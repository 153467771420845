import React from 'react';
import { Footer } from '../../containers';
import { Navbar } from '../../components';
import './mathimata.css';

const Mathimata = () => {
  return (
    <div className="Mathimata">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="Mathimata__container">
          <div className="Mathimata__header">
            <h1>Οδηγός Σπουδών</h1>
          </div>
          <div className="Mathimata__cards">
              <a href='./Gymnasio' className="Mathimata__card">
                <h3>ΓΥΜΝΑΣΙΟ</h3>
                <p>i contacted them and they said "Due to the reports we've received and our findings, we're not able to replace the Premium time you have lost. But you're free to subscribe to Premium again. Just head to your account page and enter your payment details."</p>
              </a>
              <a href='./A-Lykeiou' className="Mathimata__card">
                <h3>Α' ΛΥΚΕΊΟΥ</h3>
                <p>I contacted them and they said "Due to the reports we've received and our findings, we're not able to replace the Premium time you have lost. But you're free to subscribe to Premium again. Just head to your account page and enter your payment details."</p>
              </a>
              <a href='./B-Lykeiou' className="Mathimata__card">
                <h3>Β' ΛΥΚΕΊΟΥ</h3>
                <p>I contacted them and they said "Due to the reports we've received and our findings, we're not able to replace the Premium time you have lost. But you're free to subscribe to Premium again. Just head to your account page and enter your payment details."</p>
              </a>
              <a href='./G-Lykeiou' className="Mathimata__card">
                <h3>Γ' ΛΥΚΕΊΟΥ</h3>
                <p>I contacted them and they said "Due to the reports we've received and our findings, we're not able to replace the Premium time you have lost. But you're free to subscribe to Premium again. Just head to your account page and enter your payment details."</p>
              </a>
              <a href='./EPAL' className="Mathimata__card">
                <h3>ΕΠΑΛ</h3>
                <p>I contacted them and they said "Due to the reports we've received and our findings, we're not able to replace the Premium time you have lost. But you're free to subscribe to Premium again. Just head to your account page and enter your payment details."</p>
              </a>
              <a href='./Proetimasia' className="Mathimata__card">
                <h3>ΘΕΡΙΝΉ ΠΡΟΕΤΟΙΜΑΣΊΑ ΠΑΝΕΛΛΗΝΊΩΝ</h3>
                <p>I contacted them and they said "Due to the reports we've received and our findings, we're not able to replace the Premium time you have lost. But you're free to subscribe to Premium again. Just head to your account page and enter your payment details."</p>
              </a>
          </div>
            <div className='Mathimata__container-p'>
              <p>Με βάση το σχεδιασμό των προγραμμάτων σπουδών του Υπουργείου Παιδείας κάθε μαθητής καλείται να επιλέξει Ομάδα Μαθημάτων Προσανατολισμού στην Β’ και στην Γ’ τάξη την Κατεύθυνση Μαθημάτων η οποία αντιστοιχεί στην ΟΜΠ της επιλογής του.</p>
              <p>Οι επιλογές του θα πρέπει να συμβαδίζουν με τα απαραίτητα κριτήρια εισδοχής των κλάδων σπουδών, στα Ανώτερα και Ανώτατα Εκπαιδευτικά Ιδρύματα (ΑΑΕΙ) της Ελλάδας, καθώς κι άλλων κρατών του εξωτερικού, και τους οποίους θα ήθελε να ακολουθήσει μετά την ολοκλήρωση της φοίτησής του στη Μέση Εκπαίδευση. Ως αποτέλεσμα θα πρέπει να ερευνήσει τη συσχέτιση της κάθε Εκπαιδευτικής επιλογής στο Λύκειο με τα πλαίσια πρόσβασης τα οποία περιλαμβάνουν τους διάφορους κλάδους σπουδών των ΑΑΕΙ.</p>
              <p>Για να διαμορφώσει το πρόγραμμα φοίτησής του θα πρέπει, επίσης, να ερευνήσει τις διάφορες προσφερόμενες επιλογές και συνδυασμούς Μαθημάτων Προσανατολισμού και να επιλέξει την Κατεύθυνση που θα βοηθήσει να αξιοποιήσει τα ενδιαφέροντα, τις ικανότητες σε συνδυασμό με τους προσωπικούς, εκπαιδευτικούς και επαγγελματικούς του στόχους.</p>
              <p>Έτσι, το σχεδιάγραμμα που ακολουθεί είναι προσαρμοσμένο στην πορεία που μπορεί να ακολουθήσει ο μαθητής μας ανάλογα με την επιλογή που θα κάνει και επιλέγοντας κάθε κατεύθυνση μαθημάτων, μπορεί με τη βοήθεια του Φροντιστηρίου Άνοδος να διερευνήσει τις προσφερόμενες επιλογές στα ΑΑΕΙ.</p>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Mathimata;
