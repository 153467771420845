import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar} from '../../../../components';
import './I-Istoria-Mas.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Istoria = () => {
  return (
    <div className="Istoria">
        <div className="gradient__bg">
          <Navbar className="navbar"/>
        </div>
        <div className="Istoria__main-container">
          <div className="Istoria__container">
            <div className="Istoria__container-h">
              <h1>Η Ιστορία μας</h1>
            </div>
            <div className="Istoria__container-p">
              <p>&nbsp;&nbsp;&nbsp;Η επιχείρηση συστάθηκε το 1999 για να προσφέρει φροντιστηριακά μαθήματα υψηλού επίπεδου στην ευρύτερη περιοχή του Δήμου Σιδηροκάστρου. Από τον πρώτο καιρό έγινε φανερή η δυναμική της που μεταφράστηκε σε αυξημένους κύκλους εργασιών, αλλά και οι ευθύνες της επιχείρησης απέναντι στους πελάτες της. Σε αυτή την πορεία αναπτύχθηκαν και εισήχθησαν στην παραγωγική διαδικασία νέα διαφοροποιημένα προϊόντα και υπηρεσίες που εξυπηρέτησαν την τμηματοποίηση της αγοράς δραστηριοποίησης της.</p>
              <p>&nbsp;&nbsp;&nbsp;Τα σημαντικότερα γεγονότα που καθόρισαν την πορεία της ήταν οι δύο αλλαγές της έδρας και των χώρων λειτουργίας της εταιρείας το 2001 και το 2008, που επέτρεψαν στην εταιρεία να αναπτυχθεί περαιτέρω βελτιώνοντας την παραγωγική της ικανότητα. Ταυτόχρονα δόθηκε η δυνατότητα εκσυγχρονισμού της παραγωγικής διαδικασίας και της προσθήκης των νέων προϊόντων και υπηρεσιών.</p>
            </div>
          </div>
          <div className="Istoria__side-Containers">
              <div className="Istoria__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Πληροφορίες για Γ΄Λυκείου
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σπουδές και επαγγέλματα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
            </div>
            </div>
        <Footer />
    </div>
  )
}

export default Istoria