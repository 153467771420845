import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar, Email} from '../../../../components';
import './Gymnasio.css';

const Gymnasio = () => {
  return (
    <div className="Gymnasio">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <Email />
        <Footer />
    </div>
  )
}

export default Gymnasio