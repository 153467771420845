import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './A-Lykeiou.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Alykeiou = () => {
  return (
    <div className="Alykeiou">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="ALykeiou__main-container">
          <div className="ALykeiou__container">
            <div className="ALykeiou__container-h">
              <h1>Γ’ ΛΥΚΕΙΟΥ</h1>
              <div className="ALykeiou__container-p">
                <p>Ο μαθητής της Γ’ Λυκείου παρακολουθεί μαθήματα σύμφωνα με το πρόγραμμα σπουδών που επέλεξε.</p>
                <p>Ωστόσο, του δίνεται η δυνατότητα του Επαγγελματικού προσανατολισμού, σε περίπτωση που δεν έχει επιλέξει έγκαιρα την κατεύθυνσή του, μια παροχή που θα τον οδηγήσει πιο κοντά στον στόχο του.</p>
              </div>
              <div className="ALykeiou__container-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΘΕΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΦΥΣΙΚΗ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΧΗΜΕΙΑ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>16 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΣΠΟΥΔΩΝ ΟΙΚΟΝΟΜΙΑΣ & ΠΛΗΡΟΦΟΡΙΚΗΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΟΙΚΟΝΟΜΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Α.Ε.Π.Π.</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΣΠΟΥΔΩΝ ΥΓΕΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΒΙΟΛΟΓΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΦΥΣΙΚΗ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΧΗΜΕΙΑ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΑΝΘΡΩΠΙΣΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΑΡΧΑΙΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΛΑΤΙΝΙΚΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΙΣΤΟΡΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="ALykeiou__container-h">
              <h1>Είσαι μαθητής της Β’ ΛΥΚΕΙΟΥ και ενδιαφέρεσαι για Γ ;</h1>
            </div>
            <div className="ALykeiou__container-a">
              <a href='../Mathimata'>Θερινή προετοιμασία Πανελληνίων , έναρξη μαθημάτων 14 Ιουνίου.</a>
              <a href='../Mathimata'>Προετοιμασία Πανελληνίων, έναρξη μαθημάτων 15 Φεβρουαρίου.</a>
            </div>
          </div>
          <div className="ALykeiou__side-Containers">
              <div className="ALykeiou__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Πληροφορίες για Γ΄Λυκείου
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σπουδές και επαγγέλματα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Alykeiou