import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './email.css';

export const Email = () => {
  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };

  return (
    <div className="anodos__email">
      <div className="anodos__email-header">
        <h1>Επικοινωνήστε μαζί μας</h1>
          <div className="anodos__email-p_address">
          <p>Διέυθυνση : <a href="https://www.google.com/maps/place/%CE%A6%CF%81%CE%BF%CE%BD%CF%84%CE%B9%CF%83%CF%84%CE%AE%CF%81%CE%B9%CE%BF+%CE%9C.%CE%95+%CE%86%CE%BD%CE%BF%CE%B4%CE%BF%CF%82/@41.2368355,23.3897812,17z/data=!4m14!1m7!3m6!1s0x14a9796d891a87fb:0x5f99a1b6d11c819d!2zzqbPgc6_zr3PhM65z4PPhM6uz4HOuc6_IM6cLs6VIM6Gzr3Ov860zr_Pgg!8m2!3d41.2368355!4d23.3923561!16s%2Fg%2F11hyzffgbm!3m5!1s0x14a9796d891a87fb:0x5f99a1b6d11c819d!8m2!3d41.2368355!4d23.3923561!16s%2Fg%2F11hyzffgbm?entry=ttu" style={{ color: 'blue', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">Ρούπελ 6, Σιδηρόκαστρο 62300</a></p>
          <br/>
          <p>Τηλ : <a href="tel:+302323025175" style={{ color: 'blue', textDecoration: 'underline' }} >2323025175</a></p>
          <br/>
          <p>Email : <a href="mailto:anodos.fr6@gmail.com" style={{ color: 'blue', textDecoration: 'underline' }}>anodos.fr6@gmail.com</a></p>
          </div>
      </div>
      <div className="anodos__email-container">
        <form className="anodos__email-container_form" ref={form} onSubmit={sendEmail}>
          <label className="anodos__email-container_label">Όνομα</label>
          <input className="anodos__email-container_input" type="text" name="user_name" required/>
          <label className="anodos__email-container_label">Email</label>
          <input className="anodos__email-container_input" type="email" name="user_email" required/>
          <label className="anodos__email-container_label">Μήνυμα</label>
          <textarea className="anodos__email-container_input-message" name="message" required/>
          <input className="anodos__email-container_btn" type="submit" value="Send" />
        </form>

        {showSuccess && (
          <div className="anodos__email-container_popup anodos__email-container_popup--success">
            <span>Your message was sent successfully!</span>
            <button className="anodos__email-container_close" onClick={closePopup}>X</button>
          </div>
        )}

        {showError && (
          <div className="anodos__email-container_popup anodos__email-container_popup--error">
            <span>There was an error sending your message. Please try again later.</span>
            <button className="anodos__email-container_close" onClick={closePopup}>X</button>
          </div>
        )}
      </div>
      <div className="Map">
        <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5345.811563437474!2d23.987654!3d41.123456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a9796d891a87fb%3A0x5f99a1b6d11c819d!2zzqbPgc6_zr3PhM65z4PPhM6uz4HOuc6_IM6cLs6VIM6Gzr3Ov860zr_Pgg!5e0!3m2!1sen!2sgr!4v1693462577735!5m2!1sen!2sgr"
              width="600"
              height="450"
              allowfullscreen="true"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="Map of Anodos"
              className="map"
        ></iframe>
      </div>
    </div>
  );
};

export default Email;