import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar} from '../../../../components';
import './Oi-Kathigites-Mas.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Kathigites = () => {
  return (
    <div className="Kathigites">
        <div className="gradient__bg">
          <Navbar className="navbar"/>
        </div>
        <div className="Kathigites__main-container">
          <div className="Kathigites__container">
            <div className="Kathigites__container-h">
              <h1>Οι Καθηγητές μας</h1>
            </div>
            <div className="Kathigites__container-p">
              <p>&nbsp;&nbsp;&nbsp;Το φροντιστήριο προσφέρει σημαντικά εφόδια, αφού διαθέτει πιστοποιημένες εκπαιδευτικές δομές υψηλών προδιαγραφών (κατάλληλες αίθουσες διδασκαλίας, διαδραστικούς πίνακες, αίθουσες πολλαπλών χρήσεων, σύγχρονες υποδομές, πληροφοριακά συστήματα εκπαίδευσης κλπ). Το ανθρώπινο δυναμικό του αποτελείται από επιστήμονες µε μεγάλη εμπειρία, εξειδίκευση, κατάρτιση και, κυρίως, µε εκπαιδευτική τεχνογνωσία στο σχεδιασμό και την υλοποίηση προγραμμάτων σπουδών. Κατέχει καλά την διδακτική τέχνη, που σημαίνει αγάπη για τον μαθητή, γνώση των ιδιαιτεροτήτων του και σεβασμό της προσωπικότητας του.</p>
              <p className="p__right">&nbsp;&nbsp;&nbsp;ΚΑΡΑΒΑΣΙΛΗ ΒΑΪΑ – ΜΑΘΗΜΑΤΙΚΟΣ
                  <p>&nbsp;&nbsp;&nbsp;Είμαι απόφοιτος του Μαθηματικού τμήματος των θετικών σπουδών του πανεπιστημίου Ιωαννίνων. Στα 25 χρόνια φροντιστηριακής εκπαίδευσης έχω εντρυφήσει με υπομονή, μελέτη, μεθοδικότητα σε όλους τους κλάδους των μαθηματικών που αφορούν την δευτεροβάθμια εκπαίδευση και με οδήγησαν στη συγγραφή μαθηματικών σημειώσεων. Στόχος τους είναι η ευόδωση των προσπαθειών των μαθητών μου.</p>
                  <p>&nbsp;&nbsp;&nbsp;Κύριος σκοπός μου δεν είναι μόνο η επιτυχία των μαθητών στο σχολείο και τις εξετάσεις τους αλλά και η συνειδητοποίηση της αξίας της μαθηματικής σκέψης σε όλους τους τομείς της ζωής τους.</p> 
                  <p>&nbsp;&nbsp;&nbsp;Μαζί με άξιους, καταρτισμένους και συνεργάσιμους εκπαιδευτικούς, σε έναν χώρο λειτουργικό και όμορφο χαράσσουμε τον δρόμο για την πραγμάτωση των ονείρων και των στόχων των μαθητών μας, άλλωστε «Η εκπαίδευση είναι δύναμη που γιατρεύει την ψυχή». Πλάτωνας, Έλληνας Φιλόσοφος (427-347 π.Χ.)</p>
              </p>
              <p className="p__left">&nbsp;&nbsp;&nbsp;ΑΘΑΝΑΣΙΟΣ ΜΠΑΝΤΕΣ – ΦΙΛΟΛΟΓΟΣ
                <p>&nbsp;&nbsp;&nbsp;Αποφοίτησα από τη Φιλοσοφική Σχολή των Ιωαννίνων και συγκεκριμένα από το τμήμα του Φιλοσοφικού Παιδαγωγικού Ψυχολογικού το 1998. Από τότε βρίσκομαι στην ιδιωτική εκπαίδευση έχοντας ως κύριο στόχο να μεταδώσω στους μαθητές την αγάπη για τα φιλολογικά μαθήματα.</p>
                <p>&nbsp;&nbsp;&nbsp;Η κατανόηση της αξίας του ορθού λόγου, η διαμόρφωση κριτικής σκέψης, η συγκρότηση εκφραστικής επάρκειας και η καλλιέργεια της αντίληψης ότι η γνώση είναι πηγή χαράς συνιστούν εφόδιο για το σύνολο της ζωής διαμορφώνοντας την ποιότητα των καθημερινών ενασχολήσεων. Και οι ποιοτικές ασχολίες καταδεικνύουν και τους ποιοτικούς ανθρώπους, όπως τουλάχιστον διδάσκει η αρχαία ελληνική φιλοσοφία.</p>
                <p>&nbsp;&nbsp;&nbsp;Όμως, η συνείδηση ότι η γνώση, ως άντληση ευχαρίστησης, αποτελεί διαρκή αναζήτηση καθιστά τις σχέσεις αμφίδρομες. Ο δάσκαλος μαθαίνει πολλά από τους μαθητές του. Από αυτή την άποψη, μαζί με τους μαθητές βρίσκομαι κι εγώ σε κατάσταση διαρκούς μαθητείας. Είναι η αιώνια μαθητεία του δασκάλου. Κι αυτή ακριβώς είναι η ευτυχία του επαγγέλματός μου.</p>
                <p>&nbsp;&nbsp;&nbsp;Στο φροντιστήριο Άνοδος όλο το εκπαιδευτικό προσωπικό κινείται προς αυτή την κατεύθυνση της διαρκούς αλληλεπίδρασης με τον μαθητή. Η επίγνωση ότι ο δάσκαλος αλληλοσυμπληρώνεται με τον μαθητή δεν αποτελεί μόνο την προϋπόθεση μιας ουσιαστικής εποικοδομητικής σχέσης αλλά και το κλειδί της εκπαιδευτικής επιτυχίας. «Γηράσκω δ’ αιεί πολλά διδασκόμενος»  Σωκράτης, Έλληνας Φιλόσοφος </p>
              </p>
              <p className="p__right">&nbsp;&nbsp;&nbsp;ΓΙΑΝΝΟΥΔΗ ΟΛΓΑ - ΒΙΟΛΟΓΟΣ
                <p>&nbsp;&nbsp;&nbsp;Ονομάζομαι Όλγα Γιαννούδη και είμαι απόφοιτη του τμήματος Μοριακής Βιολογίας και Γενετικής του ΔΠΘ. Διδάσκω Βιολογία σε μαθητές/τριες λυκείου και τα μαθήματα ειδικότητας Ανατομία και Υγιεινή σε μαθητές/τριες ΕΠΑΛ.</p>
                <p>&nbsp;&nbsp;&nbsp;Η Βιολογία είναι μια επιστήμη που εξελίσσεται καθημερινά και επηρεάζει πολλούς τομείς της ζωής, γι’ αυτό και στο φροντιστήριο μας πιστεύουμε ότι η διδασκαλία του μαθήματος θα πρέπει να εξελίσσεται ανάλογα και να εμπλουτίζεται με νέες μεθόδους και εκπαιδευτικό υλικό.</p>
                <p>&nbsp;&nbsp;&nbsp;Όλα τα μέλη της καθηγητικής ομάδας έχουμε ως πρώτο μέλημα να αναπτύξουμε σχέσεις επικοινωνίας και εμπιστοσύνης με τους μαθητές και τις μαθήτριες μας και στη συνέχεια να βοηθήσουμε τον καθένα και την καθεμία ξεχωριστά ώστε να εξελιχθεί και να πετύχει τους στόχους του.</p>
                <p>&nbsp;&nbsp;&nbsp;«Η διδασκαλία είναι κάτι περισσότερο από το να διανέμεις τη γνώση, είναι να εμπνέεις την αλλαγή.» William A. Ward</p>
              </p>
              <p className="p__left">&nbsp;&nbsp;&nbsp;ΓΙΑΝΝΑΚΙΔΗΣ ΓΙΩΡΓΟΣ- ΜΑΘΗΜΑΤΙΚΟΣ
                <p>&nbsp;&nbsp;&nbsp;Σπούδασα μαθηματικός στο Αριστοτέλειο Πανεπιστήμιο, κατέχω μεταπτυχιακό στην Διοίκηση Διεθνών Επιχειρήσεων από το Πανεπιστήμιο Derby της Αγγλίας και έχω σεμινάριο Ειδικής Αγωγής του Πανεπιστημίου Αιγαίου.</p>
                <p>&nbsp;&nbsp;&nbsp;Ο λόγος για τον οποίο διδάσκω είναι για να βοηθήσω στην βελτίωση των δεξιοτήτων των μαθητών, να τους παρέχω κίνητρα για να γίνουν καλύτεροι και για να πετύχουν τους προσωπικούς τους στόχους άλλωστε «Τα παιδιά πρέπει να διδάσκονται πώς να σκέφτονται, όχι το τι να σκέφτονται». Margaret Mead</p>
                <p>&nbsp;&nbsp;&nbsp;Είμαι μέλος του Φροντιστηρίου Άνοδος για την άρτια οργάνωση του φροντιστηρίου και της τελευταίας τεχνολογίας υποδομές του στην διδασκαλία των μαθημάτων. Την άψογη συνεργασία με τους συναδέλφους και με την διοίκηση.</p>
              </p>
              <p className="p__right">&nbsp;&nbsp;&nbsp;ΧΑΤΖΗΑΓΓΕΛΙΔΗΣ ΑΝΕΣΤΗΣ – ΦΥΣΙΚΟΣ
                <p>&nbsp;&nbsp;&nbsp;Είμαι απόφοιτος του τμήματος Φυσικής του Αριστοτελείου Πανεπιστημίου Θεσσαλονίκης από το 2008. Διδάσκω στην ιδιωτική εκπαίδευση από το 2009 σε μαθητές Γυμνασίου και Λυκείου τα μαθήματα της Φυσικής και Χημείας.</p>
                <p>&nbsp;&nbsp;&nbsp;Σκοπός μου μέσα από τη διδασκαλία είναι να εμπλουτίσω στους μαθητές την φυσική σκέψη και την ικανότητα επίλυσης προβλημάτων. Πιστεύοντας στις δυνατότητες κάθε παιδιού δρω ώστε να φτάσει στο καλύτερο αποτέλεσμα. </p>
                <p>&nbsp;&nbsp;&nbsp;Στο φροντιστήριο Άνοδος έχουμε μια δυνατή ομάδα με έμπειρους και ικανούς καθηγητές οι οποίοι συνεργάζονται συνεχώς με σκοπό να πετύχουν την μέγιστη εξέλιξη του μαθητή.</p>
                <p>&nbsp;&nbsp;&nbsp;«Εκπαίδευση είναι η βαθμιαία ανακάλυψη της άγνοιας μας» Will Durant.</p>
              </p>
              <p className="p__left">&nbsp;&nbsp;&nbsp;ΑΠΟΣΤΟΛΙΔΟΥ ΕΙΡΗΝΗ – ΠΛΗΡΟΦΟΡΙΚΟΣ
                <p>&nbsp;&nbsp;&nbsp;Είμαι απόφοιτος του Τμήματος ηλεκτρονικών Yπολογιστικών Συστημάτων του πανεπιστημίου Δυτικής Αττικής. Ο προγραμματισμός είναι η μεγάλη μου αγάπη και έπειτα από ειδικά σεμινάρια και επιμορφώσεις, διδάσκω το μάθημα του ΑΕΠΠ σε μαθητές τρίτης τάξης Γενικού Λυκείου, Προγραμματισμό σε μαθητές επαγγελματικού Λυκείου και ρομποτική σε παιδιά προσχολικής ηλικίας.</p>
                <p>&nbsp;&nbsp;&nbsp;Στόχος μου είναι να περάσω την αγάπη μου για τον προγραμματισμό στα παιδιά ώστε οι υπολογιστικές μηχανές να γίνουν εργαλεία χρήσιμα για τη μετέπειτα εξέλιξή τους. «Το μυαλό δεν είναι ένα δοχείο που πρέπει να γεμίσει αλλά μία φωτιά που πρέπει να ανάψει»  Πλούταρχος, Έλληνας Ιστορικός</p>
                <p>&nbsp;&nbsp;&nbsp;Στο φροντιστήριο Άνοδος η δημιουργία ψηφιακών αιθουσών, ειδικά εξοπλισμένες με τις πιο σύγχρονες τεχνολογίες που αφορούν την εκπαίδευση, είναι αυτό που σε συνδυασμό με τη διδασκαλία κάνει τη διαφορά και  οδηγεί τον μαθητή στην καλύτερη εμπέδωση της ύλης. </p>            
              </p>
              <p className="p__right">&nbsp;&nbsp;&nbsp;ΠΑΠΑΔΗΜΗΤΡΙΟΥ ΝΙΚΟΛΕΤΑ- ΟΙΚΟΝΟΜΟΛΟΓΟΣ
                <p>&nbsp;&nbsp;&nbsp;Είμαι απόφοιτος του τμήματος Οργάνωσης και Διοίκησης Επιχειρήσεων και κάτοχος Παιδαγωγικής και Διδακτικής επάρκειας από την σχολή Κοινωνικών Επιστημών του ΕΑΠ. Έχω παρακολουθήσει αρκετά επιμορφωτικά σεμινάρια σε θέματα εκπαίδευσης, όπως συμπερίληψη στην εκπαίδευση, κριτική παιδαγωγική, συμβουλευτική και επαγγελματικός προσανατολισμός, εκπαίδευση ενηλίκων. Επιπλέον, είμαι κάτοχος πιστοποιητικού εξειδικευμένης επιμόρφωσης από το ΠΑΜΑΚ στον τομέα: «Σχολική ψυχολογία». </p>
                <p>&nbsp;&nbsp;&nbsp;Έχοντας ως κίνητρο την αγάπη για την διδασκαλία και την μετάδοση γνώσεων, εντάχθηκα το 2020 στην φροντιστηριακή εκπαίδευση και βρίσκομαι συνεχώς δίπλα στους μαθητές μου, διδάσκοντας μαθήματα του Οικονομικού τομέα. Στόχος μου δεν είναι μόνο η επιτυχία των μαθητών στις πανελλαδικές εξετάσεις αλλά να εμπνεύσω τον μαθητή και να τον αφυπνίσω ώστε να φτάσει τον στόχο του. «Εκπαιδεύοντας το μυαλό, χωρίς να εκπαιδεύσουμε την καρδιά, δεν είναι καθόλου εκπαίδευση» Αριστοτέλης (384 – 322 π.Χ.),  Έλληνας φιλόσοφος</p>
                <p>&nbsp;&nbsp;&nbsp;Επέλεξα να είμαι μέλος του φροντιστηρίου Άνοδος λόγω της άψογης συνεργασίας μεταξύ του διδακτικού προσωπικού, των άριστων τεχνολογικών υποδομών, του προσεγμένου και ευχάριστου χώρου διδασκαλίας, των δράσεων που συμμετέχει το φροντιστήριο αλλά και της πραγμάτωσης ενός κοινού οράματος.</p>
              </p>
            </div>
          </div>
          <div className="Kathigites__side-Containers">
            <div className="Kathigites__side-Container1">
              <h1>Χρήσιμες Σελίδες</h1>
              <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Πληροφορίες για Γ΄Λυκείου
                    </AccordionItemButton>
                  </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                      <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          In ad velit in ex nostrud dolore cupidatat consectetur
                          ea in ut nostrud velit in irure cillum tempor laboris
                          sed adipisicing eu esse duis nulla non.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σπουδές και επαγγέλματα
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          In ad velit in ex nostrud dolore cupidatat consectetur
                          ea in ut nostrud velit in irure cillum tempor laboris
                          sed adipisicing eu esse duis nulla non.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                  </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Kathigites