import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Oi-Xwroi-Mas.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Xwroi = () => {
  return (
    <div className="Xwroi">
        <div className="gradient__bg">
          <Navbar className="navbar" />
        </div>
        <div className="Xwroi__main-container">
          <div className="Xwroi__container">
            <div className="Xwroi__container-h">
              <h1>Οι Χώροι μας</h1>
            </div>
            <div className="Xwroi__container-p">
            <p>&nbsp;&nbsp;&nbsp;Χώροι, εγκαταστάσεις και ιδιοκτησιακό καθεστώς. </p>
              <p>&nbsp;&nbsp;&nbsp;Η εταιρεία μετέφερε την έδρα και τις εγκαταστάσεις της, αρχές του 2008, στη νέα διεύθυνση Ρούπελ 6, στον πεζόδρομο του Σιδηροκάστρου (πίσω από τα ΚΤΕΛ). Στεγάζεται στον δεύτερο όροφο νεόδομητης πολυκατοικίας. Καταλαμβάνει ολόκληρο τον όροφο και χωρίζεται σε δύο κτίρια  που ενώνονται με κοινό διάδρομο. </p>
              <p>&nbsp;&nbsp;&nbsp;Το πρώτο τμήμα περιλαμβάνει:
                  <p>&nbsp;&nbsp;&nbsp;&bull; τον χώρο υποδοχής και την γραμματεία της εταιρείας,</p> 
                  <p>&nbsp;&nbsp;&nbsp;&bull; χώρο παραμονής των σπουδαστών, κατά την διάρκεια των διαλλειμάτων,</p> 
                  <p>&nbsp;&nbsp;&nbsp;&bull; το γραφείο των καθηγητών που εργάζονται στην επιχείρηση,</p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; χώρο υγιεινής,</p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; χώρο με εγκαταστάσεις κουζίνας,</p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; 3 αίθουσες διδασκαλίας με οκτώ θέσεις σπουδαστών</p>
              </p>
              <p>&nbsp;&nbsp;&nbsp;Το δεύτερο τμήμα περιλαμβάνει:
                  <p>&nbsp;&nbsp;&nbsp;&bull; αίθουσα πολλαπλών χρήσεων με 15 θέσεις σπουδαστών,</p> 
                  <p>&nbsp;&nbsp;&nbsp;&bull; χώρο παραμονής των σπουδαστών, κατά την διάρκεια των διαλλειμάτων,</p> 
                  <p>&nbsp;&nbsp;&nbsp;&bull; αίθουσα διδασκαλίας με 8 θέσεις σπουδαστών,</p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; αίθουσα διδασκαλίας με 6 θέσεις σπουδαστών,</p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; χώρο υγιεινής,</p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; βιβλιοθήκη</p>
              </p>
            </div>
          </div>
          <div className="Xwroi__side-Containers">
            <div className="Xwroi__side-Container1">
              <h1>Χρήσιμες Σελίδες</h1>
              <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Πληροφορίες για Γ΄Λυκείου
                    </AccordionItemButton>
                  </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                      <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          In ad velit in ex nostrud dolore cupidatat consectetur
                          ea in ut nostrud velit in irure cillum tempor laboris
                          sed adipisicing eu esse duis nulla non.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σπουδές και επαγγέλματα
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          In ad velit in ex nostrud dolore cupidatat consectetur
                          ea in ut nostrud velit in irure cillum tempor laboris
                          sed adipisicing eu esse duis nulla non.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                  </AccordionItem>
              </Accordion>
            </div>
            <div className="Xwroi__side-Container2">
              <iframe src="https://anodossid.gr/Documents/Pdf/Diadrastikos.pdf" width="550" height="500" allow="autoplay" title="Diadrastikos PDF"></iframe>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Xwroi