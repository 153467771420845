import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar} from '../../../../components';
import './I-Filosofia-Mas.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Filosofia = () => {
  return (
    <div className="Filosofia">
        <div className="gradient__bg">
          <Navbar className="navbar" />
        </div>
        <div className="Filosofia__main-container">
          <div className="Filosofia__container">
            <div className="Filosofia__container-h">
              <h1>Η Φιλοσοφία μας</h1>
            </div>
            <div className="Filosofia__container-p">
              <p>&nbsp;&nbsp;&nbsp;Η «Άνοδος», ιδρύθηκε με σκοπό την παροχή υπηρεσιών φροντιστηριακής εκπαίδευσης. Κύρια στοιχεία είναι η καλύτερη οργάνωση, η καλύτερη διαχείριση του ανθρώπινου δυναμικού, η ανάπτυξη νέων παροχών και υπηρεσιών τόσο σε χώρους και εξοπλισμό, όσο και σε προγράμματα σπουδών έτσι ώστε να μπορεί να σταθεί αντάξια στις απαιτήσεις της νέας εποχής.</p>
              <p>&nbsp;&nbsp;&nbsp;Η εταιρεία δραστηριοποιείται στην παροχή υπηρεσιών συμπληρωματικής εκπαίδευσης. Απευθύνεται στους μαθητές του Γυμνασίου και Λυκείου και ιδιαίτερα της τελευταίας τάξης, όπου κυριαρχεί η δοκιμασία των εξετάσεων για το πανεπιστήμιο. Ο ρόλος του φροντιστηρίου έχει τουλάχιστον 2 διαστάσεις, την αμιγώς εκπαιδευτική πλευρά αλλά και την οικονομική και κοινωνική του συνιστώσα.</p>
              <p>&nbsp;&nbsp;&nbsp;Πρώτα η επιθυμία των μαθητών για εισαγωγή στην Τριτοβάθμια Εκπαίδευση. Όσο θα υπάρχουν, και πάντα θα υπάρχουν, σχολές υψηλής ζήτησης και σπουδές που συνδέονται µε επαγγελματική και κοινωνική καταξίωση θα συνυπάρχει και η έννοια του ανταγωνισμού των υποψηφίων για την κατάληψη αυτών των θέσεων, οι οποίες είναι θεαματικά λιγότερες από τους διεκδικητές τους, όπως άλλωστε συμβαίνει σε κάθε ανάλογη πραγματικότητα της κοινωνίας. Γεννιέται λοιπόν η ανάγκη και η επιθυμία των μαθητών και των γονέων τους να αναζητήσουν περαιτέρω εφόδια σε σχέση µε τους υπόλοιπους, να γίνουν δηλαδή ανταγωνιστικοί. Το φροντιστήριο παρέχει αυτήν ακριβώς τη δυνατότητα. Σε καμία περίπτωση δεν υποκαθιστά το σχολείο. Λειτουργεί ενισχυτικά, πολλαπλασιαστικά στις δυνάμεις που το σχολείο δίνει στο μαθητή. Το φροντιστήριο λοιπόν είναι επιλογή.</p>
              <p>&nbsp;&nbsp;&nbsp;Δεύτερη μεγάλη αναγκαιότητα που οδηγεί στα φροντιστήρια είναι οι εγγενείς αδυναμίες πολλών μαθητών να αντεπεξέλθουν στις εκπαιδευτικές απαιτήσεις της σχολικής ζωής. Τα παιδιά αυτά αισθάνονται μειονεκτικά, βιώνουν ανασφάλεια και κλονίζεται σημαντικά η αυτοπεποίθησή τους. Μάλιστα πολλά αντιδρούν αρνητικά µε αποτέλεσμα να υπάρχει αύξηση στους δείκτες της διαρροής μαθητών από τα σχολεία. Και σε αυτήν την περίπτωση, το φροντιστήριο είναι αναγκαιότητα, είναι επιλογή. Παρέχει στους μαθητές οργανωμένη και συστηματική διδασκαλία, εξατομικευμένα προγράμματα σπουδών, σημειώσεις υπό την επιμέλεια των αρμόδιων καθηγητών, επιπλέον ώρες διαγωνισμάτων και γενικά λειτουργεί µε πολύ υψηλές εκπαιδευτικές προδιαγραφές. Ως μέλος της ΟΕΦΕ (Ομοσπονδία Εκπαιδευτικών Φροντιστών Ελλάδας) έχει διαμορφώσει σύστημα διαχείρισης ποιότητας των παρεχόμενων εκπαιδευτικών υπηρεσιών (το γνωστό µας ISO).</p>
              <p>&nbsp;&nbsp;&nbsp;Η κοινωνική και οικονομική συνιστώσα είναι εξίσου σημαντική και αναφέρεται στο σύνολο της Ελληνικής κοινωνίας. Θεωρώντας ως δεδομένη την ανάγκη για εξωσχολική, φροντιστηριακού τύπου, υποστήριξη των μαθητών τότε οι επιλογές είναι δύο. Η µία είναι το φροντιστήριο και η άλλη τα ιδιαίτερα μαθήματα στο σπίτι, αμφιβόλου ποιότητας τις περισσότερες φορές, χωρίς ίχνος ελέγχου και αξιολόγησης. Η λύση των ιδιαιτέρων μαθημάτων κοστίζει στην οικογένεια τουλάχιστον 3-4 φορές περισσότερα από ότι το φροντιστήριο. Με την συμμετοχή στο φροντιστήριο μπορούν όλες οι οικογένειες να προσφέρουν στα παιδιά τους τη δυνατότητα για έναν επί ίσοις όροις ανταγωνισμό. Αυτό σημαίνει πως όλα τα παιδιά μπορούν στην πράξη, όχι θεωρητικά, να παρουσιάσουν ανοδική κοινωνική κινητικότητα. Προσφέροντας υψηλές υπηρεσίες, με κόστος που μπορούν να αντέξουν όλες οι οικογένειες και ποιότητα που ξεπερνάει κάθε άλλη αντίστοιχη διαδικασία. Αυτή η πραγματικότητα ενδυναμώνει την αρχή των ίσων ευκαιριών που αποτελεί αδιαμφισβήτητα προϋπόθεση της κοινωνικής ανάπτυξης. </p>
              <p>&nbsp;&nbsp;&nbsp;Με βάση τα παραπάνω, φροντιστήριο «Άνοδος» σημαίνει:
                  <p>&nbsp;&nbsp;&nbsp;&bull; προοπτική στο μέλλον,</p> 
                  <p>&nbsp;&nbsp;&nbsp;&bull; ανταγωνιστική ποιότητα στην διδασκαλία των μαθημάτων,</p> 
                  <p>&nbsp;&nbsp;&nbsp;&bull; απόλυτη εξειδίκευση σε μαθήματα ειδικής βαρύτητας (όπως είναι τα Μαθηματικά, η Φυσική, η Χημεία, τα Αρχαία, η Έκθεση, η Βιολογία κτλ) </p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; Εξασφάλιση  εισιτηρίου εισόδου στη σχολή που επιθυμεί ο υποψήφιος.</p>
                  <p>&nbsp;&nbsp;&nbsp;&bull; Έλεγχος και αξιολόγηση της παροχής υπηρεσιών του, κάθε χρόνο από τους ίδιους τους μαθητές του αλλά και τους γονείς τους. </p>
              </p>
            </div>
          </div>
            <div className="Filosofia__side-Containers">
              <div className="Filosofia__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Πληροφορίες για Γ΄Λυκείου
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σπουδές και επαγγέλματα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
            </div>
          
        </div>
        <Footer />
    </div>
  )
}

export default Filosofia